export default function VaultLock() {
  return (
    <div className="grid gap-x-4 md:grid-cols-2 py-4">
      <div className="flex items-center justify-center p-4">
        <a className="md:w-96 cursor-pointer inline-block rounded bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[2px] text-gray-800 hover:text-white focus:outline-none focus:ring active:text-opacity-75 w-full text-center mt-8">
          <span className="block rounded-sm bg-white px-8 py-3 text-sm font-medium hover:bg-transparent">
            Mint Xen
          </span>
        </a>
      </div>

      <div className="flex items-center justify-center p-4">
        <a className="md:w-96 cursor-pointer inline-block rounded bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[2px] text-gray-800 hover:text-white focus:outline-none focus:ring active:text-opacity-75 w-full text-center mt-8">
          <span className="block rounded-sm bg-white px-8 py-3 text-sm font-medium hover:bg-transparent">
            Lock Xen
          </span>
        </a>
      </div>

      <div className="flex items-center justify-center p-4">
        <div className="w-96 rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[3px] text-gray-800 mb-6">
          <span className="block rounded-lg bg-white dark:bg-[#0F172A] px-8 py-2 text-sm font-medium dark:text-white">
            <div className="py-8">
              <label htmlFor="principal" className="relative block overflow-hidden border-b border-gray-200 bg-transparent pt-3 focus-within:border-blue-600">
                <input type="text" id="principal" placeholder="amount" className="peer h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm text-right" defaultValue="0" />
                <span className="absolute start-0 top-2 -translate-y-1/2 text-xs transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs"> Amount </span>
              </label>
              <label className="flex justify-between mt-2">
                <span className="text-sm secondary-text">Number of Vault:</span>
                <span className="text-sm secondary-text">                      
                  <button type="button" className="primary-button-mini ml-2">Max</button>
                </span>
              </label>

              <a className="cursor-pointer inline-block rounded bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[2px] text-gray-800 hover:text-white focus:outline-none focus:ring active:text-opacity-75 w-full text-center mt-8">
                <span className="block rounded-sm bg-white px-8 py-3 text-sm font-medium hover:bg-transparent">
                  Stake Vault
                </span>
              </a>
            </div>
          </span>
        </div>
      </div>

      <div className="flex items-center justify-center p-4">
        <div className="w-96 rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[3px] text-gray-800 mb-6">
          <span className="block rounded-lg bg-white dark:bg-[#0F172A] px-8 py-2 text-sm font-medium dark:text-white">
            <div className="py-8">

              <label htmlFor="principal" className="relative block overflow-hidden border-b border-gray-200 bg-transparent pt-3 focus-within:border-blue-600">
                <input type="text" id="principal" placeholder="amount" className="peer h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm text-right" defaultValue="0" />
                <span className="absolute start-0 top-2 -translate-y-1/2 text-xs transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs"> Amount </span>
              </label>
              <label className="flex justify-between mt-2">
                <span className="text-sm secondary-text">Number of Xen:</span>
                <span className="text-sm secondary-text">
                  <button type="button" className="primary-button-mini ml-2">Max</button>
                </span>
              </label>

              <label htmlFor="term" className="relative block overflow-hidden border-b border-gray-200 bg-transparent pt-3 focus-within:border-blue-600 mt-8">
                <input type="text" id="term" placeholder="Days" className="peer h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm text-right" defaultValue="0" />                    <span className="absolute start-0 top-2 -translate-y-1/2 text-xs transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs"> Days </span>
              </label>
              <label className="flex justify-between mt-2">
                <span className="text-sm secondary-text">Number of Days</span>
                <span className="text-sm secondary-text">
                  <button type="button" className="primary-button-mini ml-2">Max</button>
                </span>
              </label>

              <a className="cursor-pointer inline-block rounded bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[2px] text-gray-800 hover:text-white focus:outline-none focus:ring active:text-opacity-75 w-full text-center mt-8">
                <span className="block rounded-sm bg-white px-8 py-3 text-sm font-medium hover:bg-transparent">
                  Lock Xen
                </span>
              </a>
            </div>
          </span>
        </div>
      </div>
    </div>
  )
}