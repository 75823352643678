import React, { useRef, useState, useContext } from 'react'

import { useAccount, useConnect, useEnsName } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'

export default function Header () {
  const { address, isConnected } = useAccount()
  const { data: ensName } = useEnsName({ address })
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })

  return (
    <div className="fixed w-full z-30 flex bg-white dark:bg-[#0F172A] p-2 items-center justify-center h-16 pl-10">
      <div className="logo ml-12 dark:text-white transform ease-in-out duration-500 flex-none h-full flex items-center justify-center">
        XenVault
      </div>
      <div className="grow h-full flex items-center justify-center"></div>

      <div className="flex-none h-full text-center flex items-center justify-center">
        <div className="flex space-x-3 items-center md:px-3">
          <div className="hidden md:hidden">Balance: 0.000 ETH</div>
          <div className="flex-none flex justify-center">
            <button onClick={() => connect()}>Connect Wallet</button>
          </div>
        </div>
      </div>
    </div>
  )
}
