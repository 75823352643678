import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import {BrowserRouter as Router} from 'react-router-dom';

import './assets/main.css'
import App from './App'

import { ThemeProvider } from './context/ThemeContext'

import { WagmiConfig, createConfig, configureChains } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { localhost, mainnet, polygon } from 'wagmi/chains'
localhost.id = 31337

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [localhost, mainnet, polygon],
  [publicProvider()],
)

const config = createConfig({
  autoConnect: true,
  publicClient,
  webSocketPublicClient,
})

const root = createRoot(document.getElementById('root'))
root.render(
  <StrictMode>
    <ThemeProvider>
      <WagmiConfig config={config}>
      <Router>
        <App />
      </Router>
      </WagmiConfig>
    </ThemeProvider>
  </StrictMode>
)
